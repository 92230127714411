@use "./mixins";
@use "./vers";

.founder {
  .mw__wrap {
    position: relative;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 920px;
  }
  .layout__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mixins.mqSP {
      flex-direction: column;
    }
  }
  .layout-left {
    width: 50%;
    @include mixins.mqPC {
      width: 360px;
      padding-left:45px;
    }
  }
  .layout-right {
    @include mixins.mqPC {
      width: 420px;
    }
  }
  .position {
    font-size: 12px;
    margin-bottom: 1em;
  }
  .name {

  }
  .pronunciation {
    font-size: 12px;
  }
  .discription {
    margin-top: 3em;
    font-size: 15px;
    line-height: 1.6;
    text-align: justify;
    @include mixins.mqSP {
      font-size: 12px;
    }
  }
}

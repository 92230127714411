@use "./mixins";
@use "./vers";

.prefooter {
  padding-top:60px;
  @include mixins.mqPC(){
    padding-top:120px;
    padding-bottom:80px;
  }
  .sns_item {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin-left: 2em;
      margin-right: 2em;
    }
  }
  .footer_fig {
    text-align: center;
    @include mixins.mqSP(){
      svg {
        width: 80%;
      }
    }
    svg path {
      fill: #DADADA;
    }

  }
}

.footer {
  padding-bottom:42px;
  @include mixins.mqSP(){
    padding-bottom:28px;
  }
  .flex {
    padding-top:80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;
    @include mixins.mqSP(){
      max-width: 60%;
      padding-top:0px;
      flex-direction:column;
    }
  }
  small {
    font-size: 1.5rem;
    display: inline-block;
    @include mixins.mqSP(){
      margin-top: 40px;
      order: 2;
    }
  }
  .item__wrap {
    display: flex;
    @include mixins.mqSP(){
      flex-direction:column;
      align-items: center;
    }
  }
  @include mixins.mqPC(){
    .terms {
      margin-right: 2em;
    }
  }
  a {
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    .icon_arrow_blank {
      margin-left: 0.4em;
      position: relative;
      bottom: -0.1em;
    }
  }
}

@use "./mixins";
@use "./vers";

.consulting {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1042px;
  }
  .description {
    text-align: center;
  }
  .item__wrap {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 40px;
    @include mixins.mqSP {
      grid-template-columns: 1fr 1fr;
    }
  }
  .item {
    a {
      text-decoration: none;
    }
    .img {
      background-color: #fff;
      line-height: 0;
    }
    .title {
      line-height: 1.2;
      font-size: 15px;
      font-weight: normal;
      margin-top: 1.0em;
    }
    .rabel {
      margin-top: 1em;
      font-size: 12px;
      display: inline-block;
      // text-align: left;
      // padding: 0.5em 1em;
      padding: 0.2em 0.6em;
      line-height: 1;
      // background-color: #ccc;
      border-left: 2px solid #FF7A00;
      color: #FF7A00;
    }
  }
  .all_item_link {
    margin-top: 120px;
    text-align: center;
    @include mixins.mqSP(){
      margin-top: 60px;
    }
    a {
      display: inline-block;
      border: 1px solid #333;
      padding: 2em 6em;
      text-decoration: none;
      @include mixins.mqSP(){
        padding: 1em 2em;
      }
    }
  }
}

@use "./mixins";
@use "./vers";

.factor {
  @include mixins.mqSP(){
    padding-top: 0;
  }
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    @include mixins.mqSP(){
      padding-top: 0;
    }
  }
  .section__sub {
    position: relative;
    @include mixins.mqPC(){
      display: flex;
      justify-content: space-between;
    }
  }
  .layout_even {
    flex-direction: row-reverse;
  }
  .layout_text {
    position: relative;
    @include mixins.mqPC(){
      width: 420px;
      padding-left: 70px;
    }
  }
  .layout_image {
    width: 530px;
    @include mixins.mqSP(){
      margin-top: 80px;
      width: 100%;
    }
  }
  .number {
    position: absolute;
    top: 10px;
    left: -40px;
    font-family: "Rajdhani", sans-serif;
    font-weight: 300;
    font-size: 600px;
    line-height: 400px;
    width: 400px;
    color: #F7F7F7;
    pointer-events: none;
    &.white {
      color: #fff;
    }
    @include mixins.mqSP(){
      font-size: 380px;
    }
  }
  .item__wrap {
    position: relative;
  }
  .title {
    padding-top: 140px;
    font-size: 24px;
    margin-bottom: 2em;
    letter-spacing: 0.3em;
  }
  h5 {
    margin-top: 1.5em;
  }
  p {
    margin-top: 1em;
    line-height: 1.6;
    text-align: justify;
  }
}

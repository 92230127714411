@use "./mixins";
@use "./vers";

.contact {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
  }
  .all_item_link {
    margin-top: 120px;
    text-align: center;
    @include mixins.mqSP(){
      margin-top: 60px;
    }
    a {
      display: inline-block;
      border: 1px solid #333;
      padding: 2em 6em;
      line-height: 1.2;
      text-decoration: none;
      @include mixins.mqSP(){
        display: block;
        padding: 2em 0;
      }
    }
  }

}

@use "./mixins";
@use "./vers";

.news {
  // padding-top: 8rem;
  // padding:;
  .mw__wrap {
    border-radius: 64px;
    // padding: 4em 4rem;
    // background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    @include mixins.mqSP(){
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .section__head {
    text-align: center;
  }
  .item__wrap {
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3em;
    border-bottom: 1px solid #999;
    .date {
      padding-bottom: 0.8em;
      width: 180px;
    }
    .description {
      width: 100%;
    }
    .item {
      border-top: 1px solid #999;
      padding-top: 3em;
      display: flex;
      position: relative;
      justify-content: left;
      @include mixins.mqSP(){
        flex-direction: column;
      }
      & + .item {
        margin-top: 3em;
      }
      .title {
        font-size: 1.6rem;
        padding-bottom: 0.5em;
        flex: 1 180px;
      }
      .text {
        line-height: 1.6;
        font-size: 1.4rem;
        a {
          display: inline-block;
          margin-top: 0.5em;
        }
      }
      strong {
        font-weight: bold;
      }
    }
  }
}

// VERS
$BreakPoint_Size: 768;
$BreakPoint: $BreakPoint_Size + px;
$spWidth: 94%;
$fontColor: #333333;
$fontColor2: #555555;
$OrangeMain: #FF792D;
$OrangeSub: #FF8A00;
$GreenMain: #2AC2C6CC;
$GreenSub: #93CAC5;
$BgGray:#F8F8F8;

body {
 background-color: #fff;
}
img {
	width: 100%;
}
@use "./mixins";
@use "./vers";

body {
  background-color: #FFFFFF;
}
// .mw__wrap {
//   @include mixins.mw_wrap();
//   background-color: #fff;
// }

@use "./mixins";
@use "./vers";

.ank_mod {
  position: relative;
  height: 120px;
  // background-color: #eee;
  padding-top: 20px;
  margin-top: 20px;
  @include mixins.mqSP(){
    height: 40px;
  }

  &:before {
    content:"";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    width: 47vw;
    height: 2px;
    background-color: #FF7A00;
  }
}
.anker_items {
  position: absolute;
  right: 20px;
  transform: rotateZ(-90deg);
  line-height: 0;
  pointer-events: none;
  font-size: 20px;
  color: #FF7A00;
  font-family: "Rajdhani", sans-serif;
  transform-origin: 100% 0;
  @include mixins.mqSP(){
    // font-size: 16px;
  }
}
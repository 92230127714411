@use "./mixins";
@use "./vers";

.information {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
  }
  .item__wrap {
    margin-top: 1.5em;
    display: flex;
    @include mixins.mqSP(){
      display: block;
      // font-size: 12px;
    }
    dt {
      @include mixins.mqPC(){
        width: 45%;
        padding-left: 10%;
        box-sizing: border-box;
      }
      @include mixins.mqSP(){
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
    }
    dd {
      @include mixins.mqPC(){
        width: 55%;
      }
      span {
        display: inline-block;
        font-size: 72%;
        padding-left: 0.5em;
      }
    }
  }

}

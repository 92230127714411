@use "./mixins";
@use "./vers";

.get-starting {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
  .sauna_visual__wrap {
    position: relative;
  }
  .sauna_visual__title {
    font-weight: normal;
    text-align: center;
    position: absolute;
    top: 50%;
    // left: 50%;
    color: #fff;
    letter-spacing: 0.4em;
    @include mixins.mqSP {
      transform: translate(0,-50%);
      width: 100%;
    }
    @include mixins.mqPC {
      position: absolute;
      top: 55%;
      left:50%;
      margin-left: -450px;
      font-size: 26px;

    }
  }
  .inner__wrap {
    margin-top: 3em;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
  }
  .section__text {
    line-height: 1.8;
    margin-top: 1em;
    text-align: justify;
  }
  .reference_head {
    margin-top: 4em;
    margin-bottom: 1em;
  }
  .reference_list {
    font-size: 11px;
    line-height: 1.4;
  }

}

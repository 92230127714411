@use "./mixins";
@use "./vers";

.header {
  position: relative;
  z-index: 20;
  height: 125px;
	@include mixins.mqSP(){
	  height: 75px;
	}
  .mw__wrap {
    position: relative;
  }
  .logo_100plus {
    position: absolute;
    width:200px;
    top: 38px;
    left: 40px;
    svg {
      width: 100%;
      height: auto;
    }
    @include mixins.mqSP(){
      top:27px;
      left:24px;
      width:102px;
    }
  }
  .head_description {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size:16px;
    font-weight: bold;
    line-height: 1.2;
    color: #111;
    line-height: 1.6;
    text-align: right;

    @include mixins.mqSP(){
      font-size:12px;
      top: 70px;
      right: 98px;
      color:#fff;
    }
  }

} // END // Block
@use "./mixins";
@use "./vers";

.product_head {
  text-align: center;
}
.product {
  .mw__wrap {
    position: relative;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
  }
  .description {
    text-align: center;
  }
  .head__caption {
    position: relative;
    text-align: center;
  }
  .item__wrap {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mixins.mqSP {
      flex-direction: column;
      align-items: center;
    }
  }
  .item {
    text-align: center;
    a {
      text-decoration: none;
    }
    .img {
      margin-left: auto;
      margin-right: auto;
    }
    @include mixins.mqSP {
      &+.item {
        margin-top: 60px;
      }
    }
    .description {
      margin-top: 1em;
      line-height: 1.6;
    }
  }
  .sanokuni {
    .img {
      width: 124px;
      @include mixins.mqPC {
        padding-bottom: 40px;
      }
    }
  }
  .sawatch {
    .img {
      width: 146px;
    }
  }
}

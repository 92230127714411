body::before {
  opacity: 0;
  transition: opacity 180ms ease-in;
}
body.pace-done::before {
  opacity: 1;
}
.main__article {
  opacity: 0;
  transition: opacity 180ms ease-in;
}
.pace-done .main__article {
 opacity: 1;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #eee;
  position: fixed;
  z-index: 2000;
  bottom: 10px;
  right: 100%;
  width: 100%;
  height: 2px;
}

@use "./mixins";
@use "./vers";

.our-goal {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
  }
  .tagline_h {
    text-align: center;
    font-size: 30px;
  }
  .tagline_d {
    text-align: center;
    font-size: 12px;
  }
  .discription_h {
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
  }
  .section__text {
    line-height: 1.8;
    margin-top: 1em;
    text-align: justify;
  }
  figrue {
    margin-top: 3em;
    display: block;
    text-align: center;
  }





  .layout__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mixins.mqSP {
      flex-direction: column;
    }
  }

}

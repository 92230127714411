@use "./mixins";
@use "./vers";
@use "./typo_set";
@use "sass:color";

html {
	font-size: 10px;
}
body {
	@include typo_set.typo--notosans();
  position: relative;
	background-color: #fff;
  color: #555;
  line-height: 1.6;
	font-size: 1.5rem;
	@include mixins.mqPC(){
		font-size: 1.6rem;
		margin-left: auto;
		margin-right: auto;
	}
}
picture,img {
  pointer-events: none;
}
.contents__wrap {
  position: relative;
  overflow-x: hidden;
}
.section h2 span {
  @include typo_set.typo--inter-notosans();
	font-weight: 700;
}
.main__article > section {
	position: relative;
}

/// PAGE VARS
$maintTxtCol: #111;
$coler_diff: #444;
$mainColor: #333;
$mainColor_light: color.scale($mainColor, $lightness: 20%);

a {	color: #111;}
a:hover { color: $mainColor_light;}
::selection {
	background-color: $mainColor;
	color: #fff;
}

.contents_link {
	&:not(&+.contents_link){
		margin-top: 80px;
	}

	&::before {
		content: "→";
		display: inline-block;
		padding-right: 0.5em;
	}
}

.section__tagline {
	font-size: 32px;
	line-height: 1.5;
	margin-top: 100px;
}
// COMMON MOD
.active_sp {
	@include mixins.mqPC {
		display: none;
	}
}
.active_pc {
	@include mixins.mqSP {
		display: none;
	}
}
p {
	line-height: 2.2;
}

section > .mw__wrap {
	padding-top: 10em;
	padding-bottom: 10em;
  margin-left: auto;
  margin-right: auto;
  @include mixins.mqSP {
    padding-top: 8em;
    padding-bottom: 7em;
    padding-left: 8%;
    padding-right: 8%;
  }
}
section.bg_gray .mw__wrap {
  @include mixins.mqSP {
    padding-top: 0;
  }
}

.section__head {
  text-align: center;
  margin-bottom: 90px;
  @include mixins.mqSP {
    margin-bottom: 60px;
  }
  span.main {
    display: block;
    font-family: "Rajdhani", sans-serif;
    font-weight: 300;
    font-size: 60px;
    color: #8B8B8B;
    line-height: 1.2;
  }
  span.sub {
    display: block;
    font-size: 16px;
    line-height: 1;
    font-weight: normal;
  }
}
.bg_gray {
  padding-top: 90px;
  background-color: #F5F6F7;
}

a .icon_arrow {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 21px;
	width: 34px;
	height: 34px;
	path {
		fill:#fff;
	}
	polygon {
		fill:#fff;
	}
}
svg {
  line-height: 0;
  vertical-align: bottom;
}

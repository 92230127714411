@use "./mixins";
@use "./vers";

// _top

.hero {
  z-index: 10;
  position: relative;
  height: 100vh;
  @include mixins.mqSP{
  }
}

.main_symbols {
  width: 328px;
  position: absolute;
  top: 24vh;
  left: 50%;
  transform: translate(-50%, -50%);
  @include mixins.mqSP{
    width: 50vw;
    svg {
      width: 100%;
    }
  }
}

.header__title {
  position: absolute;
  top: 56vh;
  width: 100%;
  text-align: center;
  font-size: 180px;
  font-weight: 300;
  line-height: 200px;
  letter-spacing: 0;
  color: #d0d0d0;
  font-family: "Rajdhani", sans-serif;
  // animate
  opacity: 0;

  @include mixins.mqSP{
    font-size: 13vw;
  }
  .pace-done & {
    animation-name: fadeIn;
    animation-duration: 340ms;
    animation-delay: 180ms;
    animation-fill-mode: forwards;
  }
}
.header__caption {
  position: absolute;
  top: 42vh;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #111;
  letter-spacing: 0.2em;
  line-height: 2.0;
  @include mixins.mqSP{
    font-size: 17px;
  }

}

@keyframes fadeIn {
	0% { opacity:0; transform: translateY(10px);}
	100% { opacity:1; transform: translateY(0);}
}

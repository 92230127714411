@use "./vers";

//  MIXINS
//  Mediaquery
//

// SP_only
@mixin mqSP() {
  @media (max-width: vers.$BreakPoint) {
    @content;
  }
}
// PC_only
@mixin mqPC() {
  @media (min-width: vers.$BreakPoint) {
    @content;
  }
}

// Hover
@mixin hover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin mw_wrap($maxwidth:1280,$sp-maxwidth:680) {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  max-width: $sp-maxwidth + px;
  @include mqPC(){
    max-width: $maxwidth + px;
    padding-right: 40px;
    padding-left: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

// flex-set
//
@mixin flexSet($align-position:flex-start,$horizontal-justify:space-between) {
  // $align-position: flex-start, flex-end, stretch
  // $horizontal-justify:  space-around,space-between
  //
  display: flex;
  justify-content: $horizontal-justify;
  align-items: $align-position;
  width: 100%;
}

// Set-Center
//
@mixin setCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fontStyle($size, $color: vers.$fontColor) {
  font-size: $size;
  color: $color;
}

@mixin letter-spacing( $s ) {
  letter-spacing: ($s / 1000) + em;
}

@function vw($size, $base: vers.$BreakPoint_Size) {
  @return calc($size / $base) * 100vw;
}

// bg-gradAnim
//

  @mixin bgGradAnim ($col01:#251431,$col02:#0E0D2B) {

  background: linear-gradient(30deg, $col01, $col02);
  background-size: 500% 500%;
  animation-name: bgGradAnim-frame;
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-delay: -4s;

  @keyframes bgGradAnim-frame {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

}

// youtube responsive
//
@mixin youtubeSet($w:16,$h:9) {
	width: 100%;
  padding-top: $h/$w*100%;
	position: relative;
	iframe{
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
	}
}


// slideshow
//

@mixin slideshowSet($slideNum:4,$slideSpeed:6000ms) {

  .slideshow,
  .slideshow:after {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  // .slideshow:after {
  //     content: '';
  // }

  .slideshow li span {
    display:block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
    animation: imageAnimation $slideNum*($slideSpeed) linear infinite 0s;
    backface-visibility: hidden;
  }

  .slideshow {
    @for $i from 1 through $slideNum {
      > li:nth-child(#{$i}) span {
        background-image: url(../img/bg_0#{$i}.png);
        animation-delay: $slideSpeed*($i - 1);
      }
    }
  }

  .no-cssanimations .slideshow li span{
    opacity: 1;
  }

  ul.slideshow{
    list-style: none;
    margin-top: 0px;
  }

  @keyframes imageAnimation {
    0% { opacity: 0; animation-timing-function: ease-in; }
    6% { opacity: 1; animation-timing-function: ease; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
  }
}

@use "./mixins";
@use "./vers";

.co-creation {
  .mw__wrap {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }
  .description {
    text-align: center;
  }
  .co-creation__fig {
    margin-top: 60px;
    // border: 1px solid #FF7A00;
    box-shadow: 0 0px 20px rgba(42, 194, 198, 0.5);
    svg {
      width: 100%;
      height: auto;
    }
  }

}

@charset "UTF-8";
/* CSS Document */

// RESET
@use "normalize";
@use "reset_add";
@use "typo_set";

// VERS
@use "_vers";

// MIXINS
@use "_mixins";
@use "_assets";

// PAGE
@use "_page";
@use "_bg";
@use "_index/_ank_mod.scss";
@use "_index/_co-creation.scss";
@use "_index/_consulting.scss";
@use "_index/_contact.scss";
@use "_index/_factor.scss";
@use "_index/_footer.scss";
@use "_index/_founder.scss";
@use "_index/_get-starting.scss";
@use "_index/_header.scss";
@use "_index/_hero.scss";
@use "_index/_information.scss";
@use "_index/_navi.scss";
@use "_index/_news.scss";
@use "_index/_our-goal.scss";
@use "_index/_product.scss";;
@use "_pacejs";
